import React, { useState } from "react"
import HomeBanner from "../components/HomeBanner/HomeBanner"
import Layout from "./../components/Layout/Layout"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Report from "../components/Report/Report"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/global/Seo/Seo"

const IndexPage = ({ data }) => {
  const homePage = useStaticQuery(graphql`
    query HomePageQuery {
      prismicBlindcvHomepage {
        data {
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          seo_metadata {
            document {
              ... on PrismicBlindcvSeoComponent {
                id
                data {
                  name
                  meta_title
                  meta_description
                  social_share_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                      publicURL
                    }
                  }
                  analytics_event_name
                  analytics_page_title
                  analytics_page_type
                }
              }
            }
          }
          banner {
            document {
              ... on PrismicBlindcvRichHeading {
                id
                data {
                  description {
                    text
                    html
                  }
                  heading {
                    text
                    html
                  }
                }
              }
            }
          }
          explore_section {
            document {
              ... on PrismicBlindcvNavigationDetails {
                id
                data {
                  nav_items {
                    description
                    href
                    icon_image {
                      alt
                      copyright
                      url
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    title {
                      text
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [isNextVisible, setNextVisible] = useState(0)
  const showNext = e => {
    setNextVisible(1)
  }
  return (
    <div>
      <Layout
        pageViewAnalyticsData={
          homePage.prismicBlindcvHomepage.data.analytics.document.data
        }
      >
        <Seo
          data={homePage.prismicBlindcvHomepage.data.seo_metadata.document.data}
        />
        <NavigationBar />
        <div className="page-content landingpage">
          <HomeBanner
            data={homePage.prismicBlindcvHomepage.data.banner.document.data}
            showNext={showNext}
          />
          {isNextVisible ? (
            <div className="next-section">
              <Report
                data={
                  homePage.prismicBlindcvHomepage.data.explore_section.document
                    .data.nav_items
                }
              />
              <SiteFooter />
            </div>
          ) : (
            ""
          )}
        </div>
      </Layout>
    </div>
  )
}
export default IndexPage
